/**
 * Represents the configuration of a workspace.
 *
 * TODO: Fetch this from API.
 */
export default function createWorkspaceConfig() {
  const env = window.location.hostname.endsWith('toolio.sk') ? 'prod' : 'dev';
  const finance = window.location.hostname === 'finance.toolio.sk' || window.location.hostname === 'localhost';

  return {
    env,
    invoice_request_email: 'milan@wikarski.sk',
    payment_request_email: 'milan@wikarski.sk',
    features: {
      roles: true,
      teams: true,
      departments: true,
      timeLogs: true,
      internalCosts: finance,
      suppliers: finance,
      priceLists: finance,
      finance: finance,
      globalSettings: finance,
    },
    currency: 'EUR',
    name: 'oh my DOT',
  };
}

/** @typedef {ReturnType<typeof createWorkspaceConfig>} WorkspaceConfig */
