import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { Box, Breadcrumbs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import TaskForm from 'components/forms/task-form/TaskForm';
import { format } from 'date-fns';
import panic from 'errors/Panic';
import { _t } from 'lang';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Link, useNavigate } from 'react-router-dom';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH, TASK_DETAIL_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import TaskPathParamProvider from 'providers/task/TaskPathParamProvider';
import { useTask } from 'providers/task/TaskProvider';
import { useMemo, useState } from 'react';
import FormLoader from 'components/forms/FormLoader';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';
import NotifyAllModal from '../../../../components/modals/NotifyAllModal';

/**
 * The content of the Edit Task page.
 */
function EditTaskImpl() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { task, loading, taskId, project } = useTask();
  const [notifyOpened, setNotifyOpened] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const initialValues = useMemo(() => {
    if (!task) {
      return undefined;
    }

    return {
      name: task.task_name,
      description: task.description ?? '',
      attachments: undefined, // attachments are hidden
      assignee: task.assignee.map(({ user_id }) => String(user_id)),
      estimatedHours: task.minutes_estimated / 60,
      start: task.start ? new Date(task.start) : '',
      deadline: task.end ? new Date(task.end) : '',
      taskPrefix: task.task_prefix,
    };
  }, [task]);

  const heading = useMemo(() => {
    if (!task) {
      return _t('Edit');
    }

    return task.parent_task_id ? _t('Edit subtask') : _t('Edit task');
  }, [task]);

  /**
   * Sends the request to the API.
   */
  const submit = ({ name, start, deadline, description, estimatedHours, assignee, notify }) => {
    const taskUpdateAction = getAction('TaskUpdateAction');

    return taskUpdateAction({
      parameters: { task_id: taskId },
      body: {
        task_name: name,
        time_estimate: estimatedHours,
        start: start ? format(start, 'yyyy-MM-dd') : undefined,
        end: deadline ? format(deadline, 'yyyy-MM-dd') : undefined,
        description,
        assignee,
        notify_assignees: notify,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Task updated'),
          message: _t("Task '%s' has been updated successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(TASK_DETAIL_PAGE_PATH.insert({ taskId }));
      })
      .catch(panic);
  };

  /**
   * Handles the submission of a form.
   *
   * @param {Object} values - The values from the form fields.
   */
  const handleSubmit = (values, isDirty) => {
    if (isDirty) {
      setFormValues(values);
      setNotifyOpened(true);
    } else {
      submit({ ...values, notify: false });
    }
  };

  /**
   * Closes the modal and handles form submission with optional notification.
   *
   * This function resets the notification state and submits the form values
   * along with an optional notification parameter.
   *
   * @param {boolean} notify - Flag to indicate if notification is enabled.
   */
  const handleModalClose = (notify) => {
    if (formValues) {
      setLoadingSubmit(true);
      submit({ ...formValues, notify });
    } else {
      setNotifyOpened(false);
    }
  };

  if (!loading && !task) {
    return <NotFoundPageContent />;
  }

  return (
    <>
      <PageHeading
        heading={heading}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
            {project && (
              <Link to={`${PROJECT_DASHBOARD_PAGE_PATH.original}?clientId=${project.client.client_id}`}>
                {project.client.client_name}
              </Link>
            )}
            {project && (
              <Link to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId: project.project_id })}>
                {project.project_full_name}
              </Link>
            )}
          </Breadcrumbs>
        }
      />
      {task ? (
        <Box pt={24}>
          <TaskForm initialValues={initialValues} onSubmit={handleSubmit} />
          <NotifyAllModal
            opened={notifyOpened}
            onClose={(notify) => handleModalClose(notify)}
            loading={loadingSubmit}
          />
        </Box>
      ) : (
        <FormLoader />
      )}
    </>
  );
}

/**
 * Displays form to edit task.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=240%3A6196
 */
export default function EditTaskPage() {
  return (
    <DashboardLayout>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <TaskPathParamProvider>
          <EditTaskImpl />
        </TaskPathParamProvider>
      </div>
    </DashboardLayout>
  );
}
