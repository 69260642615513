import { Button, Flex } from '@mantine/core';
import { _t } from '../../../../lang';

/**
 * Initializes a new instance of the CostEstimateWizard.
 */
export default function CostEstimateWizard({ disabled = true, icon, title, description, type, onClick }) {
  return (
    <Flex
      p={32}
      h={364}
      gap={16}
      align="center"
      direction="column"
      maw={256}
      w={256}
      className={`${disabled ? 'bg-neutral-100' : 'bg-white'} rounded-2xl`}
    >
      <span className={`${!disabled ? '[&_path]:stroke-main-primary' : ''}`}>{icon}</span>
      <div className={`${disabled ? 'text-neutral-300' : ''} text-2xl uppercase`}>{title}</div>
      <div className={`${disabled ? 'text-neutral-300' : ''} h-[120px] text-center text-xs`}>{description}</div>
      {!disabled ? (
        <Button type={'button'} variant="primary" onClick={() => onClick(type)}>
          {_t('Select')}
        </Button>
      ) : (
        <span className={`${disabled ? 'text-neutral-300' : ''}`}>{_t('No project')}</span>
      )}
    </Flex>
  );
}
