/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Discount" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function DiscountIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#38298B',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path
        d="M18 4L6 20M19.5 16.5C19.5 18.1569 18.1569 19.5 16.5 19.5C14.8431 19.5 13.5 18.1569 13.5 16.5C13.5 14.8431 14.8431 13.5 16.5 13.5C18.1569 13.5 19.5 14.8431 19.5 16.5ZM10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5Z"
        stroke={stroke}
      />
    </svg>
  );
}
