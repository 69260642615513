/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Prefilled" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function PrefilledIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#38298B',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path d="M21.5 3.5H2.5V20.5H21.5V3.5Z" stroke={stroke} strokeLinejoin="round" strokeDasharray="4 2" />
      <path
        d="M6.5 8.00001L16.4977 8M6.5 12L18.4977 12M6.5 16L12.4977 16"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
