/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "AddRow" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function AddRowIcon({
  className = '',
  height = 16,
  width = 18,
  stroke = '#38298B',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill={fill}
    >
      <path
        d="M1 0.500007L16.9977 0.5M1 6.50001L16.9977 6.50001M1 12.5H8.38356M12 12.5H14.5M14.5 12.5H17M14.5 12.5V10M14.5 12.5V15"
        stroke={stroke}
        strokeLinecap="round"
      />
    </svg>
  );
}
