/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "ProjectBillingTypeProject" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function ProjectBillingTypeProjectIcon({
  className = '',
  height = 64,
  width = 64,
  stroke = '#B3B3B3',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill={fill}
    >
      <path
        d="M27 36.5L9.97121 26.3393C8.19351 25.2799 8.24752 22.6874 10.0678 21.7029L26.1945 12.9814C28.569 11.6971 31.4312 11.6971 33.8057 12.9813L34 13.0864M7.90276 34.4016L25.8078 45.4181C28.3788 47 31.6213 47 34.1923 45.4181L39 42.5M52.0973 42.903L34.1922 53.9195C31.6212 55.5014 28.3788 55.5014 25.8078 53.9195L7.90271 42.903M40.6923 27.5C40.6923 30.5376 43.1548 33 46.1923 33C47.8935 33 49.4142 32.2277 50.4231 31.0146M40.6923 27.5C40.6923 24.4624 43.1548 22 46.1923 22C47.8935 22 49.4142 22.7723 50.4231 23.9854M40.6923 27.5H39M40.6923 27.5H46.6154M36.1111 38.1111H54.7778C56.496 38.1111 57.8889 36.7182 57.8889 35V20.5556C57.8889 18.8373 56.496 17.4444 54.7778 17.4444H36.1111C34.3929 17.4444 33 18.8373 33 20.5556V35C33 36.7182 34.3929 38.1111 36.1111 38.1111ZM51.6667 17.4444V11.2222C51.6667 7.78578 48.8809 5 45.4444 5C42.008 5 39.2222 7.78578 39.2222 11.2222V17.4444H51.6667Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
