/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "LogTime" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function LogTimeIcon({ className = '', height = 24, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C12.4648 1.5 12.9251 1.53811 13.3117 1.61228C13.505 1.64936 13.6838 1.69617 13.8382 1.75392C13.9839 1.80844 14.1459 1.88651 14.2729 2.005C14.4749 2.19339 14.4858 2.50978 14.2974 2.71169C14.1101 2.91249 13.7961 2.92444 13.5941 2.73925C13.593 2.73823 13.5919 2.73721 13.5908 2.73618C13.5915 2.73691 13.5922 2.73752 13.5927 2.73801"
        fill={fill}
      />
      <path d="M12 1.5C11.5352 1.5 11.0749 1.53811 10.6883 1.61228L12 1.5Z" fill={fill} />
      <path d="M10.6883 1.61228C10.495 1.64936 10.3162 1.69618 10.1618 1.75392L10.6883 1.61228Z" fill={fill} />
      <path d="M10.1618 1.75392C10.0161 1.80844 9.85406 1.88651 9.72705 2.005L10.1618 1.75392Z" fill={fill} />
      <path d="M9.72705 2.005C9.52514 2.19339 9.51418 2.50978 9.70256 2.71169L9.72705 2.005Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70256 2.71169C9.89094 2.91359 10.2073 2.92456 10.4092 2.73618L9.70256 2.71169ZM10.4092 2.73618C10.4069 2.73837 10.4056 2.73946 10.4057 2.73955L10.4092 2.73618Z"
        fill={fill}
      />
      <path d="M10.4057 2.73955C10.4057 2.73961 10.4064 2.73919 10.4079 2.73831L10.4057 2.73955Z" fill={fill} />
      <path d="M10.4079 2.73831C10.4158 2.73354 10.446 2.71532 10.5123 2.69051L10.4079 2.73831Z" fill={fill} />
      <path d="M10.5123 2.69051C10.5993 2.65795 10.7207 2.62429 10.8767 2.59437L10.5123 2.69051Z" fill={fill} />
      <path d="M10.8767 2.59437C11.1886 2.53453 11.5842 2.5 12 2.5L10.8767 2.59437Z" fill={fill} />
      <path d="M12 2.5C12.4158 2.5 12.8114 2.53453 13.1233 2.59437L12 2.5Z" fill={fill} />
      <path d="M13.1233 2.59437C13.2793 2.62429 13.4007 2.65795 13.4877 2.69051L13.1233 2.59437Z" fill={fill} />
      <path d="M13.4877 2.69051C13.554 2.71532 13.5848 2.73323 13.5927 2.73801L13.4877 2.69051Z" fill={fill} />
      <path d="M13.5927 2.73801C13.5942 2.73888 13.594 2.73931 13.5941 2.73925L13.5927 2.73801Z" fill={fill} />
      <path d="M13.5941 2.73925C13.5941 2.73922 13.5942 2.73942 13.5941 2.73925V2.73925Z" fill={fill} />
      <path
        d="M12.5 11C12.5 10.7239 12.2761 10.5 12 10.5C11.7239 10.5 11.5 10.7239 11.5 11V14C11.5 14.2761 11.7239 14.5 12 14.5C12.2761 14.5 12.5 14.2761 12.5 14V11Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.5C7.30558 5.5 3.5 9.30558 3.5 14C3.5 18.6944 7.30558 22.5 12 22.5C16.6944 22.5 20.5 18.6944 20.5 14C20.5 11.5783 19.4873 9.39313 17.8622 7.84492L19.3536 6.35355C19.5488 6.15829 19.5488 5.84171 19.3536 5.64645C19.1583 5.45118 18.8417 5.45118 18.6465 5.64645L17.1464 7.14645C17.1296 7.1633 17.1142 7.18106 17.1003 7.19957C15.6796 6.13238 13.9136 5.5 12 5.5ZM4.5 14C4.5 9.85786 7.85786 6.5 12 6.5C16.1421 6.5 19.5 9.85786 19.5 14C19.5 18.1421 16.1421 21.5 12 21.5C7.85786 21.5 4.5 18.1421 4.5 14Z"
        fill={fill}
      />
    </svg>
  );
}
