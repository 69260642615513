import PageHeading from '../../../../layouts/dashboard-layout/headers/PageHeading';
import { Box, Breadcrumbs, Button, Flex } from '@mantine/core';
import { Link } from 'react-router-dom';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from '../../../../routes/paths';
import { _t } from '../../../../lang';
import Alert from 'components/Alert';
import { useState } from 'react';
import ClientSelect from '../../../../components/selects/ClientSelect';
import CostEstimateWizard from './CostEstimateWizard';
import ProjectBillingTypeProjectIcon from '../../../../components/icons/ProjectBillingTypeProjectIcon';
import ProjectBillingTypeRetainerIcon from '../../../../components/icons/ProjectBillingTypeRetainerIcon';
import LinkWithConfirm from '../../../../components/navigate/LinkWithConfirm';

/**
 * Renders a component for selecting the type of cost estimate on a new page.
 *
 * @param {Object} props The component props.
 * @return {JSX.Element} A JSX element containing the "Choose Type" heading.
 */
export default function AddCostEstimatePageChooseType({ onClientSelect, onCostEstimateTypeSelect }) {
  const [showAlert, setShowAlert] = useState(true);
  const [disabled, setDisabled] = useState(true);

  /**
   * Callback function that handles client selection and updates UI state.
   *
   * @param {object} client - The client object that has been selected.
   */
  const onClientSelectLocal = (client) => {
    if (!client) {
      return;
    }

    onClientSelect(client);
    setDisabled(false);
  };

  return (
    <div>
      {/* Header / Edit */}
      <PageHeading
        heading={_t('Add new cost estimate')}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}>{_t('Finance')}</Link>
          </Breadcrumbs>
        }
      />

      {/* Content */}
      {showAlert && (
        <Box pt={24} pb={16}>
          <Alert
            severity="notification"
            primaryText={
              'For the desired client, choose the billing type for the projects you’re creating a cost estimate for. ' +
              '<br/> Once selected, the billing type cannot be changed!'
            }
            withCloseButton
            onClose={() => {
              // Hide the alert
              setShowAlert(false);
            }}
          />
        </Box>
      )}

      <Flex gap={16} direction="column" align="center" className="self-stretch">
        <Box py={16} className="mx-auto w-full max-w-[484px]">
          <ClientSelect placeholder={_t('Select Client')} onChange={onClientSelectLocal} />
        </Box>

        <Flex pt={16} pb={64} justify="center" align="flex-start" gap="0px 16px" wrap="wrap">
          <CostEstimateWizard
            disabled={disabled}
            icon={<ProjectBillingTypeProjectIcon />}
            title={_t('Project')}
            description={_t(
              'A predetermined cost agreed upon before starting a project, based on a clearly defined scope and specific deliverables, or a pricing model that charges for the actual hours worked on tasks within a specified timeframe.'
            )}
            type={'project'}
            onClick={onCostEstimateTypeSelect}
          />
          <CostEstimateWizard
            icon={<ProjectBillingTypeRetainerIcon />}
            title={_t('Retainer')}
            description={_t(
              'A fixed monthly fee covers a set number of hours for each role as defined in the agreement. Additional work or hours outside the agreed scope may be invoiced separately.'
            )}
            type={'retainer'}
            onClick={onCostEstimateTypeSelect}
          />
        </Flex>
      </Flex>

      <Flex height={36} py={16} justify="flex-end">
        <Button
          w={80}
          h={36}
          variant="link"
          component={LinkWithConfirm}
          to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
          confirmTitle={_t('Cancel')}
          skipConfirm={true}
        >
          {_t('Cancel')}
        </Button>
      </Flex>
    </div>
  );
}
