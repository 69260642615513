import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import ProjectPathParamProvider from 'providers/project/ProjectPathParamProvider';
import ProjectForm from 'components/forms/project-form/ProjectForm';
import { _t } from 'lang';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Box, Breadcrumbs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH } from 'routes/paths';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useProject } from 'providers/project/ProjectProvider';
import { useMemo, useState } from 'react';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';
import NotifyAllModal from '../../../components/modals/NotifyAllModal';

/**
 * The content of the EditProject page.
 */
function EditProjectImpl({ askForNotifyConfirmation = false } = {}) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { project, loading, projectId } = useProject();
  const [notifyOpened, setNotifyOpened] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const initialValues = useMemo(() => {
    if (!project) {
      return {}; // initial values are not ready yet
    }

    return {
      clientId: String(project.client.client_id),
      ownerId: String(project.owner.user_id),
      projectName: project.project_name,
      description: project.description ?? '',
      start: project.start ? new Date(project.start) : '',
      end: project.end ? new Date(project.end) : '',
      projectPrefix: project.project_prefix,
    };
  }, [project]);

  /**
   * Makes the call to the API.
   */
  const submit = ({ projectName, ownerId, members, description, start, end, notify }) => {
    const projectUpdateAction = getAction('ProjectUpdateAction');

    return projectUpdateAction({
      parameters: { project_id: projectId },
      body: {
        project_name: projectName,
        description,
        start: start ? format(start, 'yyyy-MM-dd') : undefined,
        end: end ? format(end, 'yyyy-MM-dd') : undefined,
        owner_id: ownerId,
        members,
        notify_assignees: notify,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Project updated'),
          message: _t("Project '%s' has been updated successfully.", projectName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(PROJECT_DETAIL_PAGE_PATH.insert({ projectId }));
      })
      .catch(panic);
  };

  /**
   * Handles the submission of a form.
   *
   * @param {Object} values - The values from the form fields.
   */
  const handleSubmit = (values) => {
    if (askForNotifyConfirmation) {
      setFormValues(values);
      setNotifyOpened(true);
    } else {
      submit({ ...values, notify_assignees: false });
    }
  };

  /**
   * Closes the modal and handles form submission with optional notification.
   *
   * This function resets the notification state and submits the form values
   * along with an optional notification parameter.
   *
   * @param {boolean} notify - Flag to indicate if notification is enabled.
   */
  const handleModalClose = (notify) => {
    setNotifyOpened(false);
    if (formValues) {
      submit({ ...formValues, notify });
    }
  };

  if (!loading && !project) {
    return <NotFoundPageContent />;
  }

  return (
    <>
      <PageHeading
        heading={_t('Edit project')}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
            {project && (
              <Link to={`${PROJECT_DASHBOARD_PAGE_PATH.original}?clientId=${project.client.client_id}`}>
                {project.client.client_name}
              </Link>
            )}
          </Breadcrumbs>
        }
      />
      {project && (
        <Box pt={24}>
          <ProjectForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hide={{ tasks: true }}
            disabled={{ clientId: true }}
          />
          <NotifyAllModal opened={notifyOpened} onClose={(notify) => handleModalClose(notify)} />
        </Box>
      )}
    </>
  );
}

/**
 * Displays the form to edit a project.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=271%3A7107
 */
export default function EditProjectPage() {
  return (
    <DashboardLayout>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <ProjectPathParamProvider>
          <EditProjectImpl />
        </ProjectPathParamProvider>
      </div>
    </DashboardLayout>
  );
}
