import { TextInput } from '@mantine/core';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import parseDuration from 'utils/parse-duration';

/**
 * Formats the duration in hours and minutes.
 */
function formatDuration(value) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  if (hours === 0) {
    return _t('%sm', minutes);
  }

  return _t('%sh %sm', hours, minutes);
}

/**
 * Used to input a duration in human readable format.
 *
 * @param {{
 *     value?: number;
 *     onChange?: (value: number) => void;
 *     min?: number;
 *     max?: number;
 *   }
 *   & import('@mantine/core').TextInputProps
 * }
 */
export default function DurationInput({ value = 0, onChange = noop, min = 0, max = Infinity, ...props }) {
  const [intermediateValue, setIntermediateValueImpl] = useState(value);
  const [displayValue, setDisplayValueImpl] = useState('');
  const ref = useRef(null);

  const setIntermediateValue = useCallback((value) => {
    setIntermediateValueImpl(value);
    setDisplayValueImpl(value ? formatDuration(value) : '');
  }, []);

  const setDisplayValue = useCallback((value) => {
    setDisplayValueImpl(value);
    setIntermediateValueImpl(parseDuration(value));
  }, []);

  const incIntermediateValue = useCallback(
    (delta) => {
      setIntermediateValueImpl((curr) => {
        const newValue = Math.min(max, Math.max(min, curr + delta));
        setDisplayValueImpl(newValue ? formatDuration(newValue) : '');
        return newValue;
      });
    },
    [min, max]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        incIntermediateValue(1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        incIntermediateValue(-1);
      } else if (e.key === 'Enter') {
        ref.current?.blur();
      }
    },
    [incIntermediateValue, ref]
  );

  /**
   * Called when the input loses focus.
   */
  const onBlur = () => {
    const isWithinLimits = intermediateValue <= max && intermediateValue >= min;

    if (isWithinLimits) {
      onChange(intermediateValue);
    } else {
      setIntermediateValue(value);
    }
  };

  useEffect(() => {
    setIntermediateValue(value);
  }, [value]);

  return (
    <TextInput
      ref={ref}
      {...props}
      value={displayValue}
      onChange={({ currentTarget: { value } }) => setDisplayValue(value)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      w="100%"
    />
  );
}
