import { Box, Breadcrumbs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import ProjectForm from 'components/forms/project-form/ProjectForm';
import { format } from 'date-fns';
import panic from 'errors/Panic';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Displays the form to add a project.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=271%3A7107
 */
export default function AddProjectPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getAction, userId } = useApi();

  const initialValues = {
    clientId: searchParams.get('clientId') ?? '',
    ownerId: String(userId),
    projectName: '',
    description: '',
    start: new Date(),
    tasks: [],
    projectPrefix: '',
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ clientId, ownerId, projectName, description, tasks, start, end }) => {
    const projectCreateAction = getAction('ProjectCreateAction');

    return projectCreateAction({
      body: {
        client_id: clientId,
        owner_id: ownerId,
        project_name: projectName,
        description,
        start: start ? format(start, 'yyyy-MM-dd') : undefined,
        end: end ? format(end, 'yyyy-MM-dd') : undefined,
        tasks: tasks.map(({ title }) => title).filter(Boolean),
      },
    })
      .then(({ project_id: projectId }) => {
        showNotification({
          title: _t('Project created'),
          message: _t("Project '%s' has been created successfully.", projectName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(PROJECT_DETAIL_PAGE_PATH.insert({ projectId }));
      })
      .catch(panic);
  };

  return (
    <DashboardLayout>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading
          heading={_t('Add project')}
          breadcrumbs={
            <Breadcrumbs>
              <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
            </Breadcrumbs>
          }
        />
        <Box pt={24}>
          <ProjectForm initialValues={initialValues} onSubmit={submit} />
        </Box>
      </div>
    </DashboardLayout>
  );
}
