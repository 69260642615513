/** @typedef {import('../section/CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */
/** @typedef {import('./CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */

import { Button, Collapse, Group, Stack } from '@mantine/core';
import CostEstimateSection from 'pages/finance/cost-estimate/add-cost-estimate/section/CostEstimateSection';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { _t } from 'lang';
import { createCostEstimatePartData } from 'pages/finance/cost-estimate/add-cost-estimate/part/CostEstimatePartData';
import GroupHeadline from 'components/GroupHeadline';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import useImmutable from 'hooks/use-immutable';

/**
 * Cost estimate component.
 *
 * @param props {{
 *  sections: {
 *  component: JSX.Element
 *  }[],
 *  addSection: (data: CostEstimateSectionData) => void,
 *  isInHouse: boolean
 *  }}
 * @returns {JSX.Element}
 * @constructor
 */
function CostEstimateComponent({ sections, addSection, isInHouse }) {
  const [opened, setOpened] = useState(true);

  return (
    <div className="flex flex-col rounded-2xl bg-white">
      <div className="px-2">
        <GroupHeadline heading={_t('Cost estimate')} opened={opened} setOpened={setOpened} uppercase={false} />
      </div>
      <Collapse in={opened}>
        {sections.map(({ component }) => component)}

        <Group>
          <Button
            variant="secondary"
            leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
            onClick={() => addSection({ isInHouse, rows: [{ isInHouse }, { isInHouse }, { isInHouse }] })}
          >
            {_t('Add in house section')}
          </Button>
          <Button
            variant="secondary"
            leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
            onClick={() =>
              addSection({ isInHouse: false, rows: [{ isInHouse: false }, { isInHouse: false }, { isInHouse: false }] })
            }
          >
            {_t('Add out of house section')}
          </Button>
        </Group>
      </Collapse>
    </div>
  );
}

/**
 * Project cost price Costs type.
 *
 * @param {{
 *   title: string,
 *   isInHouse: boolean,
 *   onChange: (data: CostEstimatePartData) => void,
 *   initialData: CostEstimatePartData,
 * }}
 */
export default function CostEstimatePart({ title, isInHouse, onChange, initialData }) {
  const [opened, setOpened] = useState(true);
  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimatePartData });
  const [sections, setSections] = useState(() => initialData.sections.map(createSection));

  /**
   * Handles section change.
   *
   * @param {string} id
   * @param {CostEstimateSectionData} data
   */
  function onSectionChange(id, data) {
    setSections((sections) => sections.map((section) => (section.id === id ? { ...section, data } : section)));
  }

  /**
   * Deletes a section.
   *
   * @param {string} id
   */
  function deleteSection(id) {
    setSections((sections) => sections.filter((section) => section.id !== id));
  }

  /**
   * Creates an empty section.
   *
   * @param {CostEstimateSectionData} [data]
   * @param {{
   *   autoFocus?: boolean
   * }}
   */
  function createSection(data, { autoFocus = false } = {}) {
    const id = nanoid();

    const component = (
      <CostEstimateSection
        key={id}
        id={id}
        initialData={data}
        isInHouse={data?.isInHouse ?? isInHouse}
        onChange={(data) => onSectionChange(id, data)}
        onDelete={() => deleteSection(id)}
        onDuplicate={(data) => addSection(data)} // The declaration is hoisted, so this is fine.
        autoFocus={autoFocus}
        swapSections={swapSections}
      />
    );

    return { id, component };
  }

  /**
   * Adds a section.
   *
   * @param {CostEstimateSectionData} [data]
   */
  function addSection(data) {
    const newSection = createSection(data, { autoFocus: true });
    setSections((currSections) => [...currSections, newSection]);
  }

  /**
   * Swap two sections.
   *
   * @param id1
   * @param id2
   */
  function swapSections(id1, id2) {
    setSections((sections) => {
      const index1 = sections.findIndex((section) => section.id === id1);
      const index2 = sections.findIndex((section) => section.id === id2);

      const newSections = [...sections];
      [newSections[index1], newSections[index2]] = [newSections[index2], newSections[index1]];

      return newSections;
    });
  }

  // Compute data.
  useEffect(() => {
    updateData({
      sections: sections.map(({ data }) => data).filter(Boolean),
    });
  }, [sections]);

  // Propagate data.
  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={title} opened={opened} setOpened={setOpened} />

      <Collapse in={opened}>
        <Stack spacing={16}>
          {/* TODO: Associated projects */}

          {/* Sections */}
          <CostEstimateComponent sections={sections} addSection={addSection} isInHouse />

          {/* TODO: Internal cost overview */}
        </Stack>
      </Collapse>
    </Stack>
  );
}
