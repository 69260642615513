/**
 * A functional component that returns a styled label based on the billing type.
 *
 * @param {Object} props - The component props.
 * @param {'inhouse' | 'outofhouse'} props.billingType - The type of billing ('inhouse' or 'outofhouse').
 * @return {JSX.Element} A JSX element representing the billing type label with specific styles.
 */
export default function BillingTypeLabel({ billingType }) {
  const billingTypeObject = {
    inhouse: {
      classes: 'bg-complementary-ametyst',
      text: 'In house',
    },
    outofhouse: {
      classes: 'bg-brown-500',
      text: 'Out of house',
    },
  };

  return (
    <span
      className={`${billingTypeObject[billingType].classes} flex items-start rounded-lg px-2 py-1 text-xs text-white`}
    >
      {billingTypeObject[billingType].text}
    </span>
  );
}
