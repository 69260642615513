import { _t } from '../../lang';
import { Button, Group, Modal, Stack } from '@mantine/core';

/**
 * Displays a modal dialog to notify all users about changes.
 *
 * This function renders a modal with options to either confirm or cancel sending email notifications to all users
 * assigned to a task or subtask. Based on the user's choice, it will execute the provided onClose callback
 * with a boolean value indicating the action taken.
 *
 * @param {Object} params - Parameters for the NotifyAllModal.
 * @param {boolean} params.opened - A boolean value indicating whether the modal is opened or not.
 * @param {Function} params.onClose - A callback function to be called when the user either confirms or cancels.
 *                                    It receives a boolean argument: true for confirmation, false for cancellation.
 * @return {JSX.Element} The rendered modal dialog component.
 */
export default function NotifyAllModal({ opened, onClose, loading = false }) {
  /**
   * Handles the confirmation action.
   *
   * This function performs the necessary actions upon user confirmation.
   * It sets the notification state to true and invokes the onClose callback with a true value.
   */
  const handleConfirm = () => {
    onClose(true);
  };

  /**
   * A function that handles the cancellation of an operation.
   * It triggers the onClose callback with a false argument to indicate cancellation.
   */
  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      opened={opened}
      closeOnEscape={false}
      withCloseButton={false}
      closeOnClickOutside={false}
      centered
      size={500}
      title={<span className="text-[24px] leading-[32px]">{_t('Notify about changes?')}</span>}
      onClose={handleCancel}
      padding={16}
    >
      <Stack spacing={8}>
        <p>{_t('Do you want to send email notifications to all user assigned to this task/subtask?')}</p>

        <Group position="right" spacing={16} pt={8} pb={16}>
          <Button variant="link" onClick={handleCancel} px={8} disabled={loading}>
            {_t('No, save only')}
          </Button>
          <Button variant="primary" onClick={handleConfirm} loading={loading}>
            {_t('Yes, notify')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
