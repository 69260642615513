import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { Box, Breadcrumbs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import TaskForm from 'components/forms/task-form/TaskForm';
import { _t } from 'lang';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH, TASK_DETAIL_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { format } from 'date-fns';
import panic from 'errors/Panic';
import TaskPathParamProvider from 'providers/task/TaskPathParamProvider';
import { useTask } from 'providers/task/TaskProvider';
import { useEffect } from 'react';

/**
 * The content of the AddTaskPage.
 */
function AddTaskPageImpl() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { task, taskId, setProjectId, project } = useTask();
  const { getAction } = useApi();

  useEffect(() => {
    if (projectId) {
      setProjectId(projectId);
    }
  }, [projectId]);

  /**
   * Makes the call to the API.
   */
  const submit = ({ name, description, attachments, start, deadline, assignee, estimatedHours, subtasks }) => {
    const taskCreateAction = getAction('TaskCreateAction');

    return taskCreateAction({
      parameters: { project_id: project.project_id },
      body: {
        task_name: name,
        time_estimate: estimatedHours || 0,
        start: start ? format(start, 'yyyy-MM-dd') : undefined,
        end: deadline ? format(deadline, 'yyyy-MM-dd') : undefined,
        description,
        assignee,
        subtasks: subtasks.map(({ title }) => title).filter(Boolean),
        attachments: (attachments ?? []).map(({ fileId }) => fileId),
        parent_task_id: taskId,
      },
    })
      .then(({ task_id: taskId }) => {
        showNotification({
          title: _t('Task created'),
          message: _t("Task '%s' has been created successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(TASK_DETAIL_PAGE_PATH.insert({ taskId }));
      })
      .catch(panic);
  };

  return (
    <div>
      <PageHeading
        heading={taskId ? _t('Add subtask') : _t('Add task')}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
            {project && (
              <Link to={`${PROJECT_DASHBOARD_PAGE_PATH.original}?clientId=${project.client.client_id}`}>
                {project.client.client_name}
              </Link>
            )}
            {project && (
              <Link to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId: project.project_id })}>
                {project.project_full_name}
              </Link>
            )}
            {project && task && <Link to={TASK_DETAIL_PAGE_PATH.insert({ taskId })}>{task.task_full_name}</Link>}
          </Breadcrumbs>
        }
      />
      <Box pt={24}>
        <TaskForm onSubmit={submit} withSubtasks={!taskId} withAttachments />
      </Box>
    </div>
  );
}

/**
 * Add Task form.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=240%3A6196
 */
export default function AddTaskPage() {
  return (
    <DashboardLayout>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <TaskPathParamProvider>
          <AddTaskPageImpl />
        </TaskPathParamProvider>
      </div>
    </DashboardLayout>
  );
}
