/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "AgencyFee" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function AgencyFeeIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#38298B',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path
        d="M13.3551 8.86902C12.8399 9.2363 12.2095 9.45234 11.5285 9.45234C9.78882 9.45234 8.37852 8.04204 8.37852 6.30234C8.37852 4.56264 9.78882 3.15234 11.5285 3.15234C12.6562 3.15234 13.6455 3.74492 14.202 4.63567M6.57852 19.4308H8.92778C9.23408 19.4308 9.53851 19.4673 9.83545 19.5401L12.3177 20.1434C12.8563 20.2746 13.4174 20.2873 13.9617 20.1816L16.7062 19.6476C17.4312 19.5064 18.0982 19.1592 18.6208 18.6508L20.5626 16.7619C21.1171 16.2234 21.1171 15.3495 20.5626 14.8101C20.0634 14.3244 19.2728 14.2698 18.7079 14.6816L16.4449 16.3328C16.1208 16.5696 15.7264 16.6972 15.3209 16.6972H13.1355H14.5265C15.3105 16.6972 15.9456 16.0793 15.9456 15.3167V15.0405C15.9456 14.4073 15.5026 13.8551 14.8712 13.7021L12.7243 13.1799C12.3749 13.0952 12.017 13.0523 11.6573 13.0523C10.789 13.0523 9.21722 13.7713 9.21722 13.7713L6.57852 14.8748M19.1785 7.20234C19.1785 8.94204 17.7682 10.3523 16.0285 10.3523C14.2888 10.3523 12.8785 8.94204 12.8785 7.20234C12.8785 5.46264 14.2888 4.05234 16.0285 4.05234C17.7682 4.05234 19.1785 5.46264 19.1785 7.20234ZM2.97852 14.4923V19.7123C2.97852 20.2164 2.97852 20.4684 3.07661 20.6609C3.1629 20.8303 3.30058 20.9679 3.46992 21.0542C3.66244 21.1523 3.91447 21.1523 4.41852 21.1523H5.13852C5.64256 21.1523 5.89459 21.1523 6.08711 21.0542C6.25645 20.9679 6.39413 20.8303 6.48043 20.6609C6.57852 20.4684 6.57852 20.2164 6.57852 19.7123V14.4923C6.57852 13.9883 6.57852 13.7363 6.48043 13.5437C6.39413 13.3744 6.25645 13.2368 6.08711 13.1504C5.89459 13.0523 5.64256 13.0523 5.13852 13.0523H4.41852C3.91447 13.0523 3.66244 13.0523 3.46992 13.1504C3.30058 13.2368 3.1629 13.3744 3.07661 13.5437C2.97852 13.7363 2.97852 13.9883 2.97852 14.4923Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
