import { Box, TextInput } from '@mantine/core';
import CloseIcon from 'components/icons/CloseIcon';
import SearchIcon from 'components/icons/SearchIcon';
import { _t } from 'lang';
import { useSearch } from './SearchProvider';
import { useMemo } from 'react';
import { ADVANCED_SEARCH_PAGE_PATH } from '../../../../routes/paths';
import { useNavigate } from 'react-router-dom';

/**
 * The search input component.
 */
export default function SearchInput() {
  const navigate = useNavigate();
  const { search, setSearch, setDisplayedResults, fetchSearchResults } = useSearch();

  const advancedSearchPath = useMemo(() => `${ADVANCED_SEARCH_PAGE_PATH.original}?query=${search}`, [search]);

  return (
    <TextInput
      w={500}
      placeholder={_t('Search')}
      value={search}
      onChange={(e) => setSearch(e.currentTarget.value)}
      icon={<SearchIcon stroke="#908EFB" />}
      onFocus={() => {
        if (search.length > 0) {
          setDisplayedResults(true);
          fetchSearchResults(search);
        }
      }}
      styles={{
        input: {
          backgroundColor: '#211C57',
          borderColor: '#38298B',
          lineHeight: '20px',
          fontSize: '15px',
          color: '#FFFFFF',
          height: '100%',
          borderRadius: '8px',
          '::placeholder': {
            color: '#CECDFF',
            lineHeight: '20px',
            fontSize: '15px',
          },
          '&:focus-within': {
            borderColor: '#908EFB',
            backgroundColor: '#38298B',
          },
        },
        wrapper: {
          backgroundColor: '#211C57',
          borderColor: '#38298B',
          borderRadius: '8px',
          '&:focus-within': {
            borderColor: '#908EFB',
            backgroundColor: '#38298B',
          },
        },
      }}
      rightSection={
        search !== '' && (
          <Box onClick={() => setSearch('')} className="cursor-pointer">
            <CloseIcon stroke="#FFFFFF" />
          </Box>
        )
      }
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          navigate(advancedSearchPath, { state: { showResults: false }, replace: true });
        }
      }}
    />
  );
}
