import CloseIcon from 'components/icons/CloseIcon';
import { ActionIcon, RingProgress } from '@mantine/core';
import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useInterval } from '@mantine/hooks';
import RememberIcon from './icons/RememberIcon';
import ApproveV2Icon from './icons/ApproveV2Icon';
import ActionRequiredIcon from './icons/ActionRequiredIcon';
import InformationIcon from './icons/InformationIcon';
import WarningV2Icon from './icons/WarningV2Icon';

/**
 * Severity types.
 */
export const ALERT_SEVERITY_TYPES = ['notification', 'confirmation', 'actionRequired', 'information', 'error'];

const severityClassesMap = {
  notification: 'bg-white',
  confirmation: 'bg-complementary-positive',
  actionRequired: 'bg-complementary-action',
  information: 'bg-grey-100',
  error: 'bg-complementary-error',
};

const severityDividersMap = {
  notification: 'after:bg-grey-700',
  confirmation: 'after:bg-white',
  actionRequired: 'after:bg-grey-700',
  information: 'after:bg-grey-700',
  error: 'after:bg-red',
};

const severityIconMap = {
  notification: <RememberIcon width={24} height={24} stroke="#4D4D4D" />,
  confirmation: <ApproveV2Icon width={24} height={24} stroke="#FFFFFF" />,
  actionRequired: <ActionRequiredIcon width={24} height={24} stroke="#4D4D4D" />,
  information: <InformationIcon width={24} height={24} stroke="#4D4D4D" />, // TODO: set correct icon
  error: <WarningV2Icon width={24} height={24} stroke="#BF0D38" />,
};

const textClassesMap = {
  notification: 'text-grey-700',
  confirmation: 'text-white',
  actionRequired: 'text-grey-700',
  information: 'text-grey-700',
  error: 'text-red',
};

const closeButtonMap = {
  notification: '#4D4D4D',
  confirmation: '#FFFFFF',
  actionRequired: '#4D4D4D',
  information: '#4D4D4D',
  error: '#BF0D38',
};

/**
 * Component for displaying a notification.
 *
 * @param {{
 *   severity: keyof typeof severityClassesMap,
 *   primaryText: string | React.ReactNode,
 *   secondaryText: string,
 *   className: string,
 *   displayIcon: boolean,
 *   withCloseButton: boolean,
 *   autoClose: number,
 *   autoCloseInterval: number,
 *   onClose: () => void,
 * }}
 */
export default function Alert({
  severity = 'information',
  primaryText = '',
  secondaryText = '',
  className = '',
  displayIcon = true,
  withCloseButton = false,
  autoClose,
  autoCloseInterval = 100,
  onClose = noop,
}) {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const progress = useMemo(() => Math.max(0, 1 - timeElapsed / autoClose), [timeElapsed, autoClose]);

  const { start, stop } = useInterval(() => setTimeElapsed((prev) => prev + autoCloseInterval), autoCloseInterval);

  // Start/stop the timer when autoClose changes.
  useEffect(() => {
    autoClose ? start() : stop();
  }, [autoClose]);

  // Close the alert when the timer reaches 0.
  useEffect(() => {
    if (timeElapsed >= autoClose) {
      onClose();
      stop();
    }
  }, [timeElapsed, autoClose]);

  // Stop the timer when the component unmounts.
  useEffect(() => stop, []);

  const wrapperClasses = severityClassesMap[severity];
  const dividerClasses = severityDividersMap[severity];
  const textClasses = textClassesMap[severity];
  const icon = severityIconMap[severity];

  return (
    <div className={`${className} ${wrapperClasses} flex items-stretch rounded-[8px] p-4`}>
      {displayIcon && <span className="flex items-center">{icon}</span>}
      <div
        className={
          displayIcon
            ? `${dividerClasses} relative ml-[16px] flex w-full items-stretch justify-between after:absolute after:right-full after:top-0 after:h-full after:w-[1px] after:content-['']`
            : ''
        }
      >
        <div className={`mt-[1px] flex w-full flex-col justify-center px-[16px] ${textClasses}`}>
          <span
            className="text-[15px] leading-[18px] [&_a]:underline"
            dangerouslySetInnerHTML={{ __html: primaryText }}
          ></span>
          {secondaryText && <span className="pt-2 text-[12px] leading-[16px]">{secondaryText}</span>}
        </div>
        {withCloseButton && (
          <ActionIcon
            pos="relative"
            onClick={onClose}
            variant="transparent"
            className="flex h-[24px] w-[24px] items-center justify-center"
          >
            {autoClose ? (
              <RingProgress
                pos="absolute"
                size={32}
                thickness={1}
                rootColor="transparent"
                sections={[{ value: progress * 100, style: { stroke: closeButtonMap[severity] } }]}
              />
            ) : (
              <></>
            )}
            <CloseIcon stroke={closeButtonMap[severity]} height={20} width={20} />
          </ActionIcon>
        )}
      </div>
    </div>
  );
}
